// Logic for mobile nav services toggle. 

$('.dropdown').click(function() {

    var content = $(this).find('.dropdown__wrap')[0];

	// The collapsed element must be given a max-height:0 so it can close
	// This logic just strips the inline max-height which lets it expand.
    if (window.innerWidth < 601 && content.style.maxHeight) {
        content.style.maxHeight = null;
    } else {
        content.style.maxHeight = content.scrollHeight + "px"; // set max-height to scroll height of object
    }

});

$('.dropdown').click(function() {
	$(this).toggleClass('isActive');
});

