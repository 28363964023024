if (document.getElementById('page-about')) {

//#textLeft, #textRight, #cut-line


var enkTL = gsap.timeline();

enkTL
.set('.aboutEnkodus', {opacity: 1})
.set('#cut-line line', {
    drawSVG: "50% 50%"
});

enkTL
	
    .to('#cut-line line', 1, {
        drawSVG: "0% 80%"
    }, '0.3')
    .from('#textLeft text', 1, {
        x: 200
    }, '-=0.4')
    .from('#textRight text', 1, {
        x: -200
    }, '-=1')


var shiftTL = gsap.timeline({
    scrollTrigger: {
        trigger: "#logo_h",
        start: "top top",
        end: "bottom 60%",
        scrub: 0.3,
        pinSpacing: false,
        //markers: true,
        id: "shift",
        pin: ".about-enk"
    }
});
var shift = 15;
shiftTL
    .to('#textLeft', 1, {
        x: -shift,
        y: shift
    })
    .to('#textRight', 1, {
        x: shift,
        y: -shift
    }, '-=1');



var weDesignTL = gsap.timeline({
    delay: 0.1
});
weDesignTL.from('.backdrop', 0.6, {
    alpha: 0,
    x: "-100%"
})
weDesignTL.from('.we-design h1', 0.6, {
    alpha: 0,
    x: "-100%"
}, '-=0.4')
weDesignTL.from('.we-design hr', 0.6, {
    alpha: 0,
    width: 0
}, '-=0.4')
weDesignTL.from('.we-design h2', 0.6, {
    alpha: 0,
    x: "-100%"
}, '-=0.4')


var MymatchMedia = ScrollTrigger.matchMedia({

    "(max-width: 601px)": function() {
        //console.log('XS');

        var weGrowTL = gsap.timeline({
            scrollTrigger: {
                trigger: '.we-grow',
                start: "bottom bottom",
                toggleActions: "play none resume reverse",
                //markers: true,
                id: "weGrow"
            }
        });
        weGrowTL.from('.we-grow h1', 0.6, {
            alpha: 0,
            y: 100
        });

        ScrollTrigger.create({
            animation: weDesignTL,
            trigger: '.backdrop',
            start: 'bottom bottom',
            end: 'bottom center',
            toggleActions: "play none resume reverse",
            // markers: true,
            // onEnter: ()=> console.log('onEnter'),
            // onLeave: ()=> console.log('onLeave'),
            // onEnterBack: ()=> console.log('onEnterBack'),
            // onLeaveBack: ()=> console.log('onLeaveBack'),
        });

    },
    "(min-width: 602px)": function() {
        //console.log('MD');
        var weGrowTL = gsap.timeline({
            scrollTrigger: {
                trigger: '.we-grow',
                start: "center bottom",
                toggleActions: "play none resume reverse",
                //markers: true,
                id: "weGrow"
            }
        });
        weGrowTL.from('.we-grow h1', 0.6, {
            alpha: 0,
            y: 100
        });

        ScrollTrigger.create({
            animation: weDesignTL,
            trigger: '.backdrop',
            start: 'top center',
            end: 'bottom center',
            toggleActions: "play none resume reverse",
        });

    },
    "(min-width: 1200px),(max-width: 1600)": function() {}, // Example: Media Range
    "all": function() {} // Code in here always runs
});



// gsap.utils.toArray(".-fixed").forEach((element, i) => {
//     ScrollTrigger.create({
//         trigger: element,
//         start: "top top",
//         pin: true,
//         pinSpacing: false,
//         //markers: true
//     });
// });

// Ready to tell your story
var readyTL = gsap.timeline({
    scrollTrigger: {
        trigger: '#are-you',
        start: 'center bottom',
        end: 'top top',
        scrub: true,
    }
});
readyTL
    .from('#are-you h1', 1, {
        opacity: 0,
        y: -100
    })
    .from('#questionWrap .background', 1, {
        y: '100%'
    }, '-=1');



// markers: true,
// onEnter: ()=> console.log('onEnter'),
// onLeave: ()=> console.log('onLeave'),
// onEnterBack: ()=> console.log('onEnterBack'),
// onLeaveBack: ()=> console.log('onLeaveBack'),
}