if (document.getElementById('rocketAnimation')) {
	var dur = 0.6
	var rocketTL = gsap.timeline();
	rocketTL
		.to('.rocket', dur, {css:{top:"-25%"}},'+=0.25')
		.to('#jetstream', dur, {css:{height:"118%"}}, '-=0.6')

		.to('.clouds', dur, {y:"15%"}, '-=0.6')
		.to('.clouds.clouds--lightGrey', dur, {y:"-15%"}, '-=0.6')
		.to('.clouds.clouds--grey',dur, {y:"-30%"}, '-=0.6')

		.to('.cloud', dur, {y:"100%"}, '-=0.6')
		.to('.cloud.cloud--lightGrey', dur, {y:"-40%"}, '-=0.6')
		.to('.cloud.cloud--grey',dur, {y:"-20%"}, '-=0.6')

		.to('.side-left', dur, {backgroundColor:"#C8E0F8"}, '-=0.6')
		.to('.side-right', dur, {backgroundColor:"#C8E0F8"}, '-=0.6')

		.set('#jetstream', {autoAlpha: 0},'+=0.25')

		.to(".side-left", dur, {
			x: '-100%'
		})
		.to(".side-right", dur, {
			x: '100%'
		}, "-=0.6");

	ScrollTrigger.create({
		animation: rocketTL,
		trigger: '#pinMaster',
		pin: true,
		start: "top top",
		end: "+=100%",
		scrub: 0.2,
		//markers: true
	})
}