// debounce must come before the its eventListener
var slowWindowSize = _.debounce(windowSize, 20);
// Show Breakpoints
//window.addEventListener("resize", slowWindowSize, false);

var sizeSM = 601;
var sizeMD = 992;
var sizeLG = 1200;

function windowSize () {
	var windowWidth = window.innerWidth;

	console.log(windowWidth);

	if (windowWidth < sizeSM) {
		return console.log('breakpoint: xs');
	} else if (windowWidth < sizeMD) {
		return console.log('breakpoint: sm');
	} else if (windowWidth < sizeLG) {
		return console.log('breakpoint: md');
	} else {
		return console.log('breakpoint: lg');
	}

}





