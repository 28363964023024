if (document.getElementById('crane')) {
	var dur = 2;

	var cardTL = gsap.timeline({yoyo:true});
	cardTL
		.to('#floating-card', {
		duration: dur,
		yoyo: true,
		repeat: -1,
		ease: "power1.inOut",
		motionPath: {
			path: '#motionPath',
			align: '#motionPath',
			alignOrigin: [0.5, 0.2]
		}
	});

	var tl = new gsap.timeline({repeat:-1,yoyo:true});
	tl
	.to('#ropeLeft', dur/2, { morphSVG:{shape:'#ropeCenter'}, ease: "power1.in"})
	.to('#ropeLeft', dur/2, { morphSVG:{shape:'#ropeRight'}, ease: "power1.out"});

	var cardRotation = new TimelineMax({repeat:-1, yoyo: true, repeatDelay:0});
	cardRotation
		.set('#floating-card',{rotation:-10}) // left
		.to('#floating-card', dur/2, {rotation: 2,ease: "power1.in"}) // to bottom
		.to('#floating-card', dur/2, {rotation: 10, ease: "power1.out"}) // to right
		.to('#floating-card', dur/2, {rotation: -2, ease: "power1.in"}) // to bottom
		.to('#floating-card', dur/2, {rotation: -10, ease: "power1.out"}); // to left
}