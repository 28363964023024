var arrow = `<svg  x="0px" y="0px" viewBox="0 0 192 335.9">
<path d="M134.1,167.9L7,40.9C-2.3,31.5-2.3,16.3,7,7s24.6-9.3,34,0l144,143.9c9.1,9.1,9.3,23.7,0.7,33.1L41.2,328.9
c-4.7,4.7-10.9,7-17,7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6,0-33.9L134.1,167.9z"/>
</svg>`;

function animateHover(e) {
	//console.log(e);
	if(e.classList.contains('slick-disabled')) {
		gsap.effects.shake(e, {repeat:0});
	} else {
		gsap.to(e, {keyframes:[
			{duration:0.3, ease: "power2.inOut", scale:1.2},
			{duration:0.3, ease: "power2.inOut", scale:1},
		]});
	}
	
}

var slickTarget = $('.slider');

function slickSlider(){

	slickTarget.slick({
		infinite: false,
		//appendArrows: $('.slide__imgWrap'),
		prevArrow: '<button type="button" class="slick-prev" onmouseenter="animateHover(this)" onClick="animateHover(this)">'+ arrow +'</button>',
		nextArrow: '<button type="button" class="slick-next" onmouseenter="animateHover(this)" onClick="animateHover(this)">'+ arrow +'</button>',
	});

	slickTarget.on('afterChange', function (event, slick) {
		// console.log(slick)
		// console.log(slick.currentSlide)
		if(slick.currentSlide != 2) {
			gsap.set('#Pen', {y:200});
			gsap.set('#smokelines path',{drawSVG:"0% 0%"});
		}
		if(slick.currentSlide==2) {
			//console.log('plays3')
			s3TL.play(0)
		}
	});

	slickCheck();

}



function slickCheck(){
	if (slickTarget.length) {
		if (window.innerWidth < 601 ) {
			slickTarget.slick('unslick');
			slickTarget.off();
		} else {
			if (!slickTarget.hasClass('slick-initialized')){
				slickSlider();
			}
			//console.log('scroll false');
		}
	}	
}

// debounce must come before the its eventListener
var debounce250 = _.debounce(function(e) {
	slickCheck();
	gsap.set('.slick-arrow', {clearProps:"all"});
}, 250);
window.addEventListener("resize", debounce250, false);