

if (document.getElementById('s3')) {

	gsap.set('#Pen', {y:200});
	gsap.set('#smokelines path',{drawSVG:"0% 0%"});
	
	var s3TL = gsap.timeline();
	s3TL
		.set('#Pen', {y:200})
		.set('#smokelines path',{drawSVG:"0% 0%"})
		.to('#Pen', 0.6, {y: 0})
		.to('#smokelines path', 0.6, {drawSVG:"0% 100%"}, '-=0.6')


	ScrollTrigger.create({
		animation: s3TL,
		trigger: '#s3',
		start: "top 50%",
		end: "bottom 50%",
		scrub: 1,
		//markers: true
	});


	// function playS3(){
	// 	var playS3 = gsap.timeline();
	// 	playS3.set('#Pen', {y:200});
	// 	playS3.set('#smokelines path',{drawSVG:"0% 0%"});
	// 	playS3.to('#Pen', 0.6, {y: 0});
	// 	playS3.to('#smokelines path', 0.6, {drawSVG:"0% 100%"}, '-=0.6');
	// }

	
	
	
	
	slickSlider();
}

