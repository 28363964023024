gsap.registerEffect({
    name: "shake",
    effect: function(targets, config) {
		gsap.to(targets, { 
			keyframes: [
				{duration: 0.1, x:'+='+config.shakeDistance, rotate:5},
				{duration: 0.1, x:'-='+config.shakeDistance*2, rotate:-5},
				{duration: 0.1, x:'+='+config.shakeDistance, rotate:0},
			]
		, repeat: config.repeat, repeatDelay: config.repeatDelay});
	},
    defaults: {shakeDistance: 5, shakes: 1, repeat: -1, repeatDelay: 2},
    extendTimeline: true, //now you can call the effect directly on any GSAP timeline to have the result immediately inserted in the position you define (default is sequenced at the end)
});

if (document.getElementById('icon-email')) {
	// Icon - HTML email
	gsap.set('#bubble',{scale: 0, transformOrigin:"50% 50%"})
	gsap.set('#number',{alpha: 0})

	var mailTL = gsap.timeline({delay:1});
	mailTL
		.to('#bubble', 0.6, { ease: "back.out(1.4)", scale: 1})
		.to('#number', 0.3, {alpha: 1})
		.shake('#number', {repeat:-1});

	ScrollTrigger.create({
		animation: mailTL,
		trigger: '#card-emails',
		start: "top 50%",
		end: "bottom 20%",
		toggleActions: "restart none none reverse",
		// onEnter: ()=> console.log('onEnter'),
		// onLeave: ()=> console.log('onLeave'),
		// onEnterBack: ()=> console.log('onEnterBack'),
		// onLeaveBack: ()=> console.log('onLeaveBack'),
		//markers: true
	})
}
