if (document.getElementById('s1')) {
	function animateRandomLinePops(targets) {
		var randomDelay = _.random(0, 2000);
		
		targets = ($(targets.childNodes));
	
		_.delay(function(){
			var tl = gsap.timeline({repeat:-1, repeatDelay:2});
			tl.to(targets, 0.6, {drawSVG:"0% 100%",ease: "power1.inOut"});
			tl.to(targets, 0.6, {drawSVG:"100% 100%",ease: "power1.Out"},'-=0.3')
		}, randomDelay);	
	}
	
	function animateRandomCircles(targets) {
		var randomDelay = _.random(0, 2000);
		_.delay(function(){
			var tl = gsap.timeline({repeat:-1, repeatDelay:3});
			tl.to(targets, 1.5, {scale: 1, ease: "elastic.out(1, 0.5)"})	
			tl.to(targets, 1, {scale: 0, ease: "elastic.in(1, 0.5)"})
		}, randomDelay);
	}
	
	
	var s1Circles = _.shuffle($('#s1 .circle'));
	var s2Circles = _.shuffle($('#s2 .circle'));
	var s3Circles = _.shuffle($('#s3 .circle'));
	
	gsap.set('.circle', {transformOrigin:"50% 50%", scale: 0});
	
	
	
	if (s1Circles) {
		_.each(s1Circles, function(targets){
			animateRandomCircles(targets)
		});
		_.each(s2Circles, function(targets){
			animateRandomCircles(targets)
		});
		_.each(s3Circles, function(targets){
			animateRandomCircles(targets)
		});
	}
	
	var s1Crosses = _.shuffle($('#s1 .cross'));
	var s2Crosses = _.shuffle($('#s2 .cross'));
	var s3Crosses = _.shuffle($('#s3 .cross'));
	
	// console.log(s1Circles);
	// console.log(s2Circles);
	// console.log(s3Circles);
	// console.log(s1Crosses);
	// console.log(s2Crosses);
	// console.log(s3Crosses);

	gsap.set('.cross .line', {drawSVG:"0% 0%"})
	
	if(s1Crosses) {
		_.each(s1Crosses, function(targets){
			animateRandomLinePops(targets)
		});
		_.each(s2Crosses, function(targets){
			animateRandomLinePops(targets)
		});
		_.each(s3Crosses, function(targets){
			animateRandomLinePops(targets)
		});
	}
	
	
}