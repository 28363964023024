if (document.getElementById('building-rocket')) {
	var rbTL = gsap.timeline({repeat:-1, repeatDelay: 1});

	//MorphSVGPlugin.convertToPath('#mouth-open','#mouth-closed');
	MotionPathPlugin.convertToPath('#mouth-open');
	MotionPathPlugin.convertToPath('#mouth-closed');
	gsap.set('#arm-right', {transformOrigin: "100% 100%", rotation: -35});
	gsap.set('#blueprint', {transformOrigin: "100% 60%", rotation: 12});

	rbTL
		.to('#mouth-open', 0.8, {morphSVG:{shape:'#mouth-closed'},  ease: "power2.out"})
		.to('#helmet', 0.8, {transformOrigin: "50% 0%", rotation:12, x:2,  ease: "power2.out"},'-=0.8')
		.to('#XMLID_175_', 0.8, {y:-1, x:-2,  ease: "power2.out"},'-=0.8')
		.to('#arm-right', 0.8, {transformOrigin: "100% 100%", rotation: 0, ease: "back.out(1.7)"}, '-=0.8')
		.to('#blueprint', 0.8, {transformOrigin: "100% 60%", rotation: 0, ease: "back.out(1.7)"}, '-=0.8')
		
		.to('#mouth-open', 0.8, {morphSVG:{shape:'#mouth-open'},  ease: "power2.out"},'+=1.5')
		.to('#helmet', 0.8, {transformOrigin: "50% 0%", rotation:0, x:0,  ease: "power2.out"},'-=0.8')
		.to('#XMLID_175_', 0.8, {y:0, x:0,  ease: "power2.out"},'-=0.8')
		.to('#arm-right', 0.8, {transformOrigin: "100% 100%", rotation: -35, ease: "back.out(1.7)"}, '-=0.8')
		.to('#blueprint', 0.8, {transformOrigin: "100% 60%", rotation: 12, ease: "back.out(1.7)"}, '-=0.8');
}
	
	