if (document.getElementById('icon-banner')) {
	// Icon - Rich Media Banner Ads
	var adTL = gsap.timeline({yoyo: true, delay:1, repeat:-1, repeatDelay:1});
	gsap.set(['#add-text','#add-exit','#add-overlay'],{alpha: 0});
	gsap.set(['#add-background', '#add-border'],{scale: 0, transformOrigin:"50% 50%"});
	adTL
		.to('#add-overlay', 0.6, {alpha: 1})
		.to(['#add-background','#add-border'], 0.6, { ease: "back.out(1.1)", scale: 1},'-=0.2')
		.to(['#add-background','#add-border'], 0.6, { alpha: 1},'-=0.6')
		.to(['#add-text','#add-exit'], 0.6, {alpha: 1});
}