(function () {
	//console.log('hello');

	$("body").removeClass("preload");
	console.log('removed preload');

	// setTimeout
	setTimeout(function(){

		//var response = grecaptcha.getResponse();
		// if (!response) {
		// 	alert('Coud not get recaptcha response'); 
		// 	return; 
		// }

	}, 1000);

	
})();