if (document.getElementById('contact')) {
	
	gsap.set('.star', {transformOrigin: "50% 50%"});

	gsap.fromTo('.star', 8,{rotation:0},{rotation:360, ease:"none", repeat:-1});

	gsap.fromTo('.star',
	{
		scale: 1.2
	},
	{
		scale: 'random(0.5,1.2)',
		duration: 'random(1,2)',
		ease: "power2.out",
		stagger: {
			each: 0.2,
			yoyo: true,
			repeat: -1,
			repeatRefresh: true
		}
	});


	gsap.fromTo('.speedline',
	{
		alpha: 1,
		drawSVG: "0 0"
	},
	{
		duration: 'random(0.5, 1)',
		drawSVG: "0 100",
		repeat: -1,
		repeatDelay: 2,
		onRepeat: function() {
			var tl = gsap.timeline();
			tl
				.set('.speedline', {alpha: 1})
		},
		stagger: {
			each: 0.25,
			onComplete: function(){
				//console.log(this._targets[0]);
				var tl = gsap.timeline();
				var target = this._targets[0];
				tl
					.to(target, 0.5, {drawSVG: "100 100"})
					.set(target, {drawSVG:"0 0"})
					.set(target, {alpha: 0})
			}

		}
	});

}