// See methods on a Target
function getMethods(obj)
{
    var res = [];
    for(var m in obj) {
        if(typeof obj[m] == "function") {
            res.push(m)
        }
    }
    return res;
}
//console.log(getMethods(Target));

function insertAfter(insertEl, referenceNode) {
    referenceNode.parentNode.insertBefore(insertEl, referenceNode.nextSibling);
}

function insertBefore(insertEl, referenceNode) {
    referenceNode.parentNode.insertBefore(insertEl, referenceNode);
}

function select(target) {
	return document.querySelector(target);
}

function selectAll(targets) {
	return document.querySelectorAll(targets);
}

function setInlineMaxHeight (target) {
	target.style.maxHeight = target.scrollHeight + "px";
}

function clearInlineMaxHeight (target) {
	target.style.maxHeight = null;
}

// 