// Main Navigation

document.getElementById('mainNavigationToggle').addEventListener('click', toggleMainNav);
//document.getElementById('close-main-navigation').addEventListener('click', toggleMainNav);

function staggerTargets() {
    var tl = gsap.timeline(); 
    tl.
        staggerTo('#mainNavigation .menuItem', 0.6, {css:{left:0, opacity:1}}, 0.15, "+=0.6");
    return tl;
}

// Create Timeline
var timeline = gsap.timeline({paused:true});
timeline.add(staggerTargets());

// Toggle State
var isMainNavHidden = true;

function toggleMainNav () {
    console.log('clicked');
    document.getElementById('mainNavigation').classList.toggle('isToggled');
    document.getElementById('mainNavigationToggle').classList.toggle('isToggled');
    
    //Set State to opposite value
    isMainNavHidden = !isMainNavHidden; 

    if (!isMainNavHidden) {
        timeline.progress(0);
        timeline.play();
    }

}