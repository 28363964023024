if ( document.getElementById('icon-video') ) {

	// Icon - Balloon Animation
	var balloonTL = gsap.timeline({yoyo:true, repeat:-1, repeatDelay:1});
	balloonTL
		.set(['#cross-h','#cross-v'],{scale:0, transformOrigin:"50% 50%"})
		.to('#scrubber', 4, 
		{
			x:190, 
			ease: "power1.inOut", 
			onStart:function(){
				wind();
			}
		})
		.to('#cloud-front', 4, {xPercent:-20, ease: "power1.inOut"}, '-=4')
		.to('#cloud-back', 4, 
			{
				xPercent:15, 
				ease: "power1.inOut", 
				onComplete: function() {
					reverseWind();
				}
			}, '-=4')
		.to('#star', 4, {rotation:360, transformOrigin:"50% 50%", ease: "power1.inOut"}, '-=4')
		.to('#balloon',2,{y:-15, ease: "back.inOut(2)"},'-=4')
		.to('#balloon',2,{y:0, ease: "back.inOut(2)"},'-=2')
		.to('#cross-h',0.5,{scale:1, transformOrigin:"50% 50%", ease: "back.out(2)"}, '-=2')
		.to('#cross-v',0.5,{scale:1, transformOrigin:"50% 50%", ease: "back.out(2)"}, '-=2')
		
	function wind() {
		var windTL = new TimelineMax({repeat: 1});
		windTL
			.set('#wind-top', {drawSVG:"0 0"})
			.set('#wind-bottom', {drawSVG:"0 0"})
			
			.to('#wind-top', 0.5, {drawSVG:"0 100"})
			.to('#wind-bottom', 0.5, {drawSVG:"0 100"},'-=0.2')
	
			.to('#wind-top', 0.5, {drawSVG:"105 105"})
			.to('#wind-bottom', 0.5, {drawSVG:"80 100"},'-=0.2')
			return windTL;
	}
	
	function reverseWind () {
		var windTLreverse = new TimelineMax({repeat: 1});
		windTLreverse
			.set('#wind-top', {drawSVG:"105 105"})
			.set('#wind-bottom', {drawSVG:"80 100"})
	
			.to('#wind-top', 0.5, {drawSVG:"0 100"}, '+=1')
			.to('#wind-bottom', 0.5, {drawSVG:"0 100"},'-=0.2')
			.to('#wind-top', 0.5, {drawSVG:"0 0"})
			.to('#wind-bottom', 0.5, {drawSVG:"0 0"},'-=0.2')
			return windTLreverse;
	}
	
}