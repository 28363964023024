if (document.getElementById('contact')) {

    //gsap.registerPlugin(ScrollTrigger);

    // if there are objects that may get inline styles added (like via tweens) that should get reverted, use ScrollTrigger.saveStyles() initially so that the current inline styles are saved for later reversion. It's not always necessary, but it goes well with ScrollTrigger.matchMedia() so we figured it'd make sense to show it here (it's not needed in this demo)
    //ScrollTrigger.saveStyles(".mobile, .desktop");

    /*** Different ScrollTrigger setups for various screen sizes (media queries) ***/

    // var sizeSM = 601;
    // var sizeMD = 992;
	// var sizeLG = 1200;

	var fixSign = gsap.timeline({
		scrollTrigger: {
			trigger: "#rocket",
			pin: '#contact-title',
			start: "top top",
			scrub: true,

		}
	});

	var rocketParallaxTL = gsap.timeline({
		scrollTrigger: {
			trigger: "#rocket",
			start: "-=100px top",
			end: "bottom top",
			scrub: 0.2,
			//markers: true,
			//onToggle: ({isActive}) => console.log(isActive)
		}
	});

    var formParallax = ScrollTrigger.matchMedia({

        "(max-width: 601px)": function() {
			//console.log('XS');

			var laxTargets = gsap.utils.toArray(".-lax");
			var laxTargets = $(".-lax");

			for (var i=0; i<laxTargets.length; i++) {
				var depth = laxTargets[i].getAttribute('data-depth');
				//console.log('depth: ',depth);
				var movement = -(50 * depth);
				rocketParallaxTL.to(laxTargets[i], {y: movement, ease: "none"}, 0)
			}

			// gsap.utils.toArray(".-lax").forEach(target => {
			// 	const depth = target.dataset.depth;
			// 	const movement = -(100 * depth);
			// 	rocketParallaxTL.to(target, {y: movement, ease: "none"}, 0)
			// });

			

        },
        "(min-width: 602px)": function() {
			//console.log('MD');

			

			var laxTargets = gsap.utils.toArray(".-lax");
			var laxTargets = $(".-lax");

			for (var i=0; i<laxTargets.length; i++) {
				var depth = laxTargets[i].getAttribute('data-depth');
				//console.log('depth: ',depth);
				var movement = -(100 * depth);
				rocketParallaxTL.to(laxTargets[i], {y: movement, ease: "none"}, 0)
			}

			// gsap.utils.toArray(".-lax").forEach(target => {
			// 	const depth = target.dataset.depth;
			// 	const movement = -(100 * depth);
			// 	rocketParallaxTL.to(target, {y: movement, ease: "none"}, 0)
			// });
        },
        "(min-width: 1200px),(max-width: 1600)": function() {}, // Example: Media Range
        "all": function() {

			

		} // Code in here always runs
    });







    
	


}