// recaptcha valdiation
function recaptchaOnload  () {
	if (grecaptcha === undefined) {
		alert('Recaptcha not defined'); 
		return; 
	}
	grecaptcha.execute();
	//console.log('finished recaptchaOnload');
}

function reportCallback (token) {
	console.log('recaptcha callback success');
	//console.log('token: ', token);
}
function reportCallbackError (token) {
	console.log('recaptcha callback error');
	//console.log('token: ', token);
};



// Form Validation using jqueryValidate
var formInput = $('.form__field');

if (formInput) {

    // If input has been touched
    formInput.on("focusin", function (e) {
        $(this).addClass('isTouched');
    });

    $.validator.setDefaults({
        ignore: []
	});
	
    $('form').validate({
        errorClass: "show-error",
        rules: {
            phone: {
                required: false,
                minlength: 9,
                maxlength: 10
            },
            otherTopic: {
                required: function () {
                    if ($('#topic').val() == "other") {
                        return true;
                    } else {
                        return false;
                    }
                }
						},
        },
        messages: {
            name: "Please enter your name.",
            email: {
                required: "Please enter an email address."
            },
            phone: {
                required: "Please enter a phone number",
                minlength: "Your number must be atleast 9 digits.",
                maxlength: "Your number can't be more than 10 digits."
			},
			topic: "Please select a topic."
        },
        errorPlacement: function (error, element) {
            element.parent().find('.errorMessage').html(error[0].innerText);
        },
        highlight: function (element, errorClass, validClass) {
            $(element).parent().addClass(errorClass).removeClass(validClass);
            $(element).parent().find('.errorMessage').addClass(errorClass + " animated shake");
        },
        unhighlight: function (element, errorClass, validClass) {
            $(element).parent().removeClass(errorClass).addClass(validClass);
            $(element).parent().find('.errorMessage').removeClass(errorClass + " animated shake");
		},
		submitHandler: function(form, event) {
			event.preventDefault();

			

			$.ajax({
				url: form.action,
				type: form.method,
				data: $(form).serialize(),  
				success: function (result) {

					function clearFields(form) {
						var form = $(form);
						form.find("input[type=text], input[type=email], input[type=number],textarea").val("");
						form.find(".form__field").removeClass("isTouched valid form__field--reveal");
						form.find("select")[0].options.selectedIndex = 0;
						form.find('.nice-select .current').html("Select Topic:");
					}
					clearFields(form);
		
					function showSuccess(form) {
						function showSuccess(form) {
							var form = $(form);
							form.find('.form__overlay').addClass('visible');
							form.find('.form__overlay').one('click', function() {
								$(this).removeClass('visible');
								$(this).find('.form__success').removeClass('-reveal');
							});
							form.find('.form__success').addClass('-reveal');
						}
						showSuccess(form);
					}
					showSuccess(form);
				},
				error: function (error) {
					function showError(form) {
						var form = $(form);
						form.find('.form__overlay').addClass('visible');
						form.find('.form__overlay').one('click', function() {
							$(this).removeClass('visible');
							$(this).find('.form__error').removeClass('-reveal');
						});
						form.find('.form__error').addClass('-reveal');
					}
					showError(form);
				}     
			});
		  },
    });

}

// var form = $('.form');
// function showError(form) {
// 	var form = $(form);
// 	form.find('.form__overlay').addClass('visible');
// 	form.find('.form__overlay').one('click', function() {
// 		$(this).removeClass('visible');
// 		$(this).find('.form__error').removeClass('-reveal');
// 	});
// 	form.find('.form__error').addClass('-reveal');
// }
// showError(form);

/* Dropdown Check */
$('select#topic').on("change", checkForDataReveal);

var savedIndex = null;
function checkForDataReveal() {

	var selectedIndex = $(this)[0].selectedIndex;
	
	// console.log('savedIndex: ',savedIndex);
	// console.log('selectedIndex: ',selectedIndex);

	savedIndex = selectedIndex;

	var theData = $(this)[0][selectedIndex].dataset;
	//console.log('theData: ', theData);

	// if selectedForm currently has selected option with a data-reveal value
	// find element with corresponding data-hidden value and reveal it
	if (theData.reveal) {
		var theTarget = $('[data-hidden="'+theData.reveal+'"]');
		theTarget.addClass('-reveal');

		function hideRevealedData() {

			if (savedIndex == selectedIndex) {
				//console.log('same value ignoring');
				return;
			} 

			theTarget.removeClass('-reveal');
			$(this).off("change", hideRevealedData);
		}

		$(this).on("change", hideRevealedData);

		ScrollTrigger.refresh();

	}
}

// add focus class to .form__detail
$('.form__field').focusin(function(){
	$(this).addClass('focus');
});
$('.form__field').focusout(function(){
	$(this).removeClass('focus');
});